import Vue from 'vue'
import Vuex from 'vuex'
import Auth from './modules/auth';
import Services from './modules/services';

Vue.use(Vuex)

// Create store
const store = new Vuex.Store({
    modules: {
        Auth,
        Services
    }
})
export default store
