<template>
  <li
      class="calendar-day"
      :class="{
      'calendar-table__inactive': !day.isCurrentMonth || isMonday || isBeforeToday,
      'calendar-day--today': isToday
    }"
      @click="handleClick"
  >
    <div class="os-day-box">
      <div class="os-day-number">{{ label }}</div>
      <div class="os-day-status" v-if="!isMonday && !isBeforeToday && selectedAgent">
        <MiniTimeslotSelector
            :selectedDate="formatSelectedDate(day.date)"
            :selectedWorker="this.selectedAgent"
        />
      </div>
    </div>
  </li>
</template>

<script>
import dayjs from 'dayjs';
import MiniTimeslotSelector from "./MiniTimeslotSelector";

export default {
  name: 'CalendarMonthDayItem',
  components: { MiniTimeslotSelector },
  mounted() {
    console.log(this.selectedAgent); // This should output the value of selectedWorker if it's being passed correctly
  },
  props: {
    day: {
      type: Object,
      required: true,
    },
    selectedAgent: {
      type: String,
      required: true,
    },
    isCurrentMonth: {
      type: Boolean,
      default: false,
    },
    isToday: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    label() {
      return dayjs(this.day.date).format('D');
    },
    isMonday() {
      return dayjs(this.day.date).day() === 1;
    },
    isBeforeToday() {
      return dayjs(this.day.date).isBefore(dayjs(), 'day');
    },
  },
  methods: {
    handleClick() {
      if (!this.isMonday && !this.isBeforeToday) {
        this.$emit('click', this.day);
      }
    },
    formatSelectedDate(dateString) {
      const dateParts = dateString.split('-');
      const year = parseInt(dateParts[0]);
      const month = parseInt(dateParts[1]);
      const day = parseInt(dateParts[2]);
      return { year, month, day };
    },
  },
};
</script>

<style scoped lang="scss">
.calendar-day {
  position: relative;
  font-weight: 500;
  background-color: #fff;
  color: #14161d;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

.calendar-day--today {
  .os-day-box {
    .os-day-number{
      font-weight: 900;
      background: #222;
      color: #fff2f4;
    }

    .os-day-status{
      background: #222;
      height: 10px;
      width: 36px;
      left: 5px;
      right: 0;
      > * {
        display: none;
      }
    }
  }
}

.calendar-table__inactive {
  color: #dcdce3;
  cursor: not-allowed;
}

@media (max-width: 360px) {
  .calendar-day {
    min-height: 33px;
    font-size: 15px;
    font-weight: 500;
    padding: 4px 0 0 0;
  }
}

.os-day-status {
  margin: 0;
  height: 4px;
  position: absolute;
  border-radius: 0;
  overflow: hidden;
  bottom: 3px;
  left: 8px;
  right: 8px;
  background-color: #e6e9ef
}

.os-day-box{
  padding: 8px 5px;
  position: relative;
}
</style>
