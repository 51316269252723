<template>
  <div class="calendar-date-indicator mt-2 mb-2"><strong>{{ selectedMonth }}</strong></div>
</template>

<script>
export default {
  props: {
    selectedDate: {
      type: Object,
      required: true
    }
  },

  computed: {
    selectedMonth() {
      return this.selectedDate.format("MMMM YYYY");
    }
  }
};
</script>

<style scoped>
.calendar-date-indicator {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif !important;
  font-weight: 900 !important;
  font-size: 21px !important;
  color: #1f222b;
  text-align: center;
}

@media (max-width: 360px) {
  .calendar-date-indicator {
    font-weight: 500 !important;
    font-size: 15px !important;
    margin: 0 0 !important;
  }
}
</style>
