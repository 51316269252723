import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/users/Home.vue'
import Calendar from '@/views/admin/Calendar'
import Events from '@/views/admin/Events'
import Account from '@/views/users/Login'
import Login from '@/components/Login'
import PageNotFound from '@/views/PageNotFound';
import store from '@/store/index';

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/account',
    component: Account,
    children: [
      {
        path: '',
        name: 'login',
        component: Login
      },
    ]
  },
  {
    path:'/calendar/:agent/',
    name: 'Calendar',
    component: Calendar,
    meta: { requiresAuth: true } //requires admin auth
  },
  {
    path:'/events/:date/',
    name: 'Events',
    component: Events,
    props: true,
    meta: { requiresAuth: true } //requires admin auth
  },
  //always put after all the other routes
  {
    path: '*',
    component: PageNotFound
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to,from,next) =>{
  if(to.matched.some(record => record.meta.requiresAuth)){
    if(store.state.Auth.isAuth){
      next();
    }
    else{
      next('/account');
      // next()
    }
  }
  else {
    next();
  }
});

export default router
