<template>
  <div class="calendar-date-selector">
    <span @click="selectPrevious">
      <font-awesome-icon icon="arrow-left"/>
  </span>
    <!--span @click="selectCurrent">Today</span-->
    <span @click="selectNext">
      <font-awesome-icon icon="arrow-right"/>
    </span>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "CalendarModeSelector",

  props: {
    currentDate: {
      type: String,
      required: true
    },

    selectedDate: {
      type: Object,
      required: true
    }
  },

  methods: {
    selectPrevious() {
      let newSelectedDate = dayjs(this.selectedDate).subtract(1, "month");
      this.$emit("dateSelected", newSelectedDate);
    },
    selectCurrent() {
      let newSelectedDate = dayjs(this.currentDate);
      this.$emit("dateSelected", newSelectedDate);
    },
    selectNext() {
      let newSelectedDate = dayjs(this.selectedDate).add(1, "month");
      this.$emit("dateSelected", newSelectedDate);
    }
  }
};
</script>

<style scoped>
.calendar-date-selector {
  display: flex;
  justify-content: space-between;
  width: 90%;
  color: #222;
  position: relative;
  top: -30px;
  left: 15px;
}

.calendar-date-selector > * {
  color: #caa569;
  cursor: pointer;
  user-select: none;
}
</style>
