<template>
  <div class="timeslot-selector">
    <div class="os-times-w">
      <div class="timeslots">
        <div
          v-for="(time, index) in workingHours"
          :key="index"
          class="dp-timepicker-trigger dp-timeslot with-tick"
          :class="{
            booked: isBooked(time),
            disabled: time === '19:00',
            past: isPastTime(time),
          }"
          @click="toggleTicks(time)"
        >
          <span class="dp-label" v-show="selectedTimeSlot === time">
            <span class="dp-label-time">{{ time }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import axios from 'axios';

export default {
  props: {
    selectedDate: {
      type: Object,
      required: true,
    },
    selectedWorker: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      workingHours: [],
      selectedTimeSlot: null,
      today: dayjs().format('DD MMMM'),
      mainWorker: 'Ridir',
      case: 0,
      updatedTime: '',
      showUpdatedTime: false,
      selectedBeginTime: '',
      timing: '',
      showEveryHour: true,
      bookedHours: [],
      currentTime: dayjs().format('HH:mm'), // Current device time
    };
  },
  mounted() {
    this.generateWorkingHours();
    this.fetchDataForDay();
  },
  watch: {
    selectedDate(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchDataForDay();
      }
    },
  },
  methods: {
    generateWorkingHours() {
      const startHour = 9;
      const endHour = 19;
      const endMinute = 0;
      this.workingHours = [];

      let hour = startHour;
      let minute = 0;

      while (hour < endHour || (hour === endHour && minute <= endMinute)) {
        const time = `${String(hour).padStart(2, '0')}:${String(
          minute,
        ).padStart(2, '0')}`;
        this.workingHours.push(time);

        minute += 15;
        hour += Math.floor(minute / 60);
        minute %= 60;
      }
    },
    showTicks(index) {
      return this.showEveryHour ? index % 4 === 0 : true;
    },
    formatTick(time) {
      return time.split(':')[0].replace(/^0/, '');
    },
    isSelected(time) {
      if (!this.selectedTimeSlot) return false;
      const [selectedStart, selectedEnd] = this.selectedTimeSlot
        .split(' - ')
        .map((t) => this.workingHours.indexOf(t));
      const currentTimeIndex = this.workingHours.indexOf(time);
      return (
        currentTimeIndex >= selectedStart && currentTimeIndex <= selectedEnd
      );
    },
    isPastTime(time) {
      if (
        dayjs(this.selectedDate).format('DD MMMM') !== dayjs().format('DD MMMM')
      ) {
        // If the selected date is not today, no timeslots should be considered as past
        return false;
      }
      const currentTime = dayjs().format('HH:mm'); // Current device time
      return time < currentTime;
    },
    async fetchDataForDay() {
      const worker = this.selectedWorker
        ? this.selectedWorker
        : this.mainWorker;
      const formattedDate = dayjs(this.selectedDate).format('YYYY-MM-DD');
      try {
        const response = await axios.get(
          `https://tessenderlobarbershop-d74df-default-rtdb.europe-west1.firebasedatabase.app/${worker}/calendar/${formattedDate}.json`,
        );
        const dayData = response.data || {};
        this.bookedHours = this.getBookedTimeSlots(dayData);
      } catch (error) {
        console.error('Error fetching data for day:', error);
      }
    },
    getBookedTimeSlots(dayData) {
      const bookedTimeSlots = [];
      for (let [timeRange, bookingInfo] of Object.entries(dayData)) {
        if (bookingInfo?.booked) {
          const [startTime, endTime] = timeRange
            .split('-')
            .map((time) => time.trim());
          let currentTime = startTime;
          bookedTimeSlots.push(currentTime); // Push the start time of the range
          while (currentTime !== endTime) {
            const nextTime = this.getNextTimeSlot(currentTime);
            if (nextTime !== null && nextTime !== endTime) {
              bookedTimeSlots.push(nextTime); // Push the start time of each 15-minute interval within the range
            }
            currentTime = nextTime;
          }
        }
      }
      return bookedTimeSlots;
    },
    getNextTimeSlot(currentTime) {
      const index = this.workingHours.indexOf(currentTime);
      if (index === -1 || index === this.workingHours.length - 1) return null;
      return this.workingHours[index + 1];
    },
    isBooked(time) {
      return this.bookedHours.includes(time);
    },
    toggleTicks(time) {
      this.selectedTimeSlot = time;
      this.$emit('timeslotClicked', time); // Emit the custom event
    },
  },
};
</script>

<style scoped>
.disabled {
  display: none;
}

.dp-timepicker-trigger.disabled {
  opacity: 0.5;
  pointer-events: none; /* Prevent clicking */
}

.timeslot-selector {
  padding: 0.5rem;
  margin: 30px 0 30px 0;
}

/* Add your custom styling here */
.timeslots {
  display: flex;
  justify-content: space-between;
}

.timeslots.slots-not-available {
  grid-template-columns: repeat(1, 1fr) !important;
}

.timeslots .not-working-message {
  border-radius: 4px;
  padding: 6px;
}

.time-selector-w.style-timebox .times-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-top: 10px;
}

.time-selector-w.style-timebox .times-header .times-header-label {
  flex: 1;
  margin: 0 15px;
  white-space: nowrap;
}

.time-selector-w.style-timebox .times-header .th-line {
  height: 1px;
  flex: 1;
  background-color: rgba(0, 0, 0, 0.1);
  content: '';
}

.time-selector-w.style-timeline {
  padding-bottom: 30px;
  flex-wrap: nowrap;
}

.time-selector-w.with-end-time.time-system-12 .timeslots {
  grid-template-columns: repeat(2, 1fr);
}

.time-selector-w.with-end-time.time-system-24 .timeslots {
  grid-template-columns: repeat(3, 1fr);
}

.time-selector-w.without-end-time.time-system-12 .timeslots {
  grid-template-columns: repeat(4, 1fr);
}

.time-selector-w.without-end-time.time-system-24 .timeslots {
  grid-template-columns: repeat(5, 1fr);
}

.time-selector-w.with-end-time.time-system-24 .timeslots {
  grid-template-columns: repeat(3, 1fr);
}

.time-selector-w.without-end-time.time-system-12 .timeslots {
  grid-template-columns: repeat(4, 1fr);
}

.time-selector-w.without-end-time.time-system-24 .timeslots {
  grid-template-columns: repeat(5, 1fr);
}

.dp-timeslot {
  height: 24px;
  flex-grow: 1;
  margin: 0;
  box-shadow: inset 1px 0 0 0#fff, inset -1px 0 0 0#fff;
  border-radius: 0;
  position: relative;
  cursor: pointer;
  background-color: #8fe286;
}

.dp-timeslot .dp-label {
  text-align: center;
  padding: 7px 8px;
  line-height: 1;
  background-color: #4169e1;
  color: #fff;
  font-size: 11px;
  font-weight: 500;
  position: absolute;
  left: 50%;
  top: -8px;
  transform: translate(-50%, -100%);
  white-space: nowrap;
  transition: all 0.2s ease;
  z-index: 10;
}

.picky {
  color: #16171d;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 1px;
  border-bottom: none;
}

.dp-label {
  font-size: clamp(10.834px, 0.999rem + ((1vw - 3.2px) * 0.955), 26px);
  font-style: normal;
  letter-spacing: 0px;
  line-height: 1;
  text-decoration: none;
  text-transform: uppercase;
  color: #caa583;
  font-weight: 900;
  position: relative;
}

.dp-label::before {
  content: ''; /* Create an empty content */
  position: absolute; /* Position it absolutely */
  bottom: -5px; /* Adjust the distance from the bottom */
  left: 50%; /* Position it horizontally in the middle */
  transform: translateX(-50%); /* Adjust horizontal position */
  border-style: solid;
  border-width: 5px 5px 0 5px; /* Adjust the size to form a triangle */
  border-color: #4169e1 transparent transparent transparent; /* Adjust color */
}

.dp-timeslot .dp-tick {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 7px;
  color: rgba(0, 0, 0, 0.4);
  line-height: 1.4;
  font-weight: 500;
}

.dp-timeslot .dp-tick strong {
  display: block;
  color: #000;
  font-size: 10px;
  line-height: 1;
  letter-spacing: 0;
  white-space: nowrap;
}

.picked-time {
  color: #16171d;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  padding-bottom: 40px;
  letter-spacing: 1px;
}

.booked {
  background-color: #e42806;
  color: white; /* Adjust text color if needed */
}

.dp-timeslot.selected {
  /* Example style for selected timeslot */
  background-color: #f0f0f0; /* Light grey, adjust as needed */
}

.no-padding {
  padding-bottom: 10px;
}
/* Add more styles as needed */

.selected-dates {
  border-bottom: 1px dotted;
}

@media (max-width: 360px) {
  .selected-dates {
    font-size: 14px;
  }
  .modal-footer {
    margin-top: 70px;
  }
}

.past {
  background-color: #6e6e73 !important;
}
</style>
