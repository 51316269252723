<template>
  <div class="calendar-date-indicator mt-2"><strong>{{ selectedMonth }}</strong></div>
</template>

<script>
export default {
  props: {
    selectedDate: {
      type: Object,
      required: true
    }
  },

  computed: {
    selectedMonth() {
      return this.selectedDate.format("MMMM YYYY");
    }
  }
};
</script>

<style scoped>
.calendar-date-indicator {
  text-align: center;
  color: #caa569;
  font-size: 25px;
  font-weight: 900;
}
</style>
