<template>
  <div class="calendar-month">
    <div class="calendar-container">
      <div class="calendar-month-header">
        <CalendarDateIndicator
          :selected-date="selectedDate"
          class="calendar-month-header-selected-month"
        />
        <CalendarDateSelector
          :current-date="today"
          :selected-date="selectedDate"
          @dateSelected="selectDate"
        />
      </div>
      <CalendarWeekdays />
      <div class="calendar-container__body">
        <div class="calendar-table">
          <div class="calendar-table__body">
            <div class="calendar-table__row">
              <CalendarMonthDayItem
                v-for="day in days"
                :key="day.date"
                :day="day"
                :is-today="day.date === today"
                @click="selectDay(day)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="events-container">
        <span class="events__title"
          >{{ selectedWorker }} Calendar
          <font-awesome-icon
            :icon="isEventListExpanded ? 'arrow-up' : 'arrow-down'"
            class="float-right text-right"
            @click="toggleEventList"
          />
        </span>
        <span class="events__title" style="margin: 0 0 0 0; padding: 0 0 0 0">{{
          nextEventTime
        }}</span>
        <TimeslotSelector
          :selectedDate="selectedDate"
          :selectedWorker="selectedWorker"
          @timeslotClicked="scrollToEvent"
        />
        <ul class="events__list" v-if="isEventListExpanded">
          <li
            class="events__item"
            v-for="(timeslot, index) in selectedDayTimeslots"
            :key="index"
            :id="timeslot?.time?.split('-')[0].trim().replace(':', '-')"
            :ref="timeslot?.time?.split('-')[0].trim().replace(':', '-')"
            @click="goToService(timeslot)"
          >
            <div class="events__item--left">
              <span class="events__name">{{
                timeslot?.bookingInfo?.client?.name +
                ' ' +
                timeslot?.bookingInfo?.client?.lastName
              }}</span>
              <span class="events__date">{{
                selectedDate.format('dddd DD-MM-YYYY')
              }}</span>
            </div>
            <span class="events__tag">{{ timeslot.time }}</span>
          </li>
        </ul>
        <!-- Modal for Service Selection -->
        <b-modal
          :id="'modal-details'"
          title="Summary"
          hide-footer
          size="lg"
          centered
        >
          <div
            class="os-summary-contents"
            style="padding: 0 30px 30px !important"
          >
            <div class="booking-summary-info-w">
              <div class="summary-box main-box">
                <div class="summary-attributes sa-clean">
                  <div class="inline-avatar">
                    <div
                      class="os-avatar-small"
                      v-if="selectedWorker === 'Ridir'"
                      style="
                        background-image: url(https://ccmihai.github.io/portfolio-template/images/header/me.png);
                      "
                    ></div>
                    <div
                      class="os-avatar-small"
                      v-if="selectedWorker === 'Maan'"
                      style="
                        background-image: url(https://qph.cf2.quoracdn.net/main-thumb-487762713-200-ldqifdohlrsonsomzyrjhrtnvlrjezxp.jpeg);
                      "
                    ></div>
                    <div class="agent-container">
                      <span class="agent" v-if="selectedWorker === 'Ridir'"
                        >{{ $t('Agent') }}: <strong>RIDIR</strong></span
                      >
                      <span class="agent" v-if="selectedWorker === 'Maan'"
                        >{{ $t('Agent') }}: <strong>MAAN</strong></span
                      >
                      <span class="agent"
                        >{{ $t('Date') }}:
                        <strong>{{
                          selectedDate.format('dddd DD-MM-YYYY')
                        }}</strong></span
                      >
                      <span class="agent" v-if="slotsData?.time"
                        >{{ $t('Time') }}:
                        <strong>{{ slotsData?.time }}</strong></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="price-breakdown-w">
              <div class="pb-heading">{{ $t('Customer Informations') }}</div>
              <div class="summary-price-item-w">
                <div class="spi-name">
                  {{ getClientName }}
                  <span class="ml-1">{{ getClientLastName }}</span>
                </div>
              </div>
              <div class="summary-price-item-w" v-if="getClientEmail">
                <div class="spi-name">{{ getClientEmail }}</div>
              </div>
              <div class="summary-price-item-w" v-if="getClientPhone">
                <div class="spi-name">{{ getClientPhone }}</div>
              </div>
            </div>
            <div class="price-breakdown-w">
              <div class="pb-heading">{{ $t('Services') }}</div>
              <div
                class="summary-price-item-w"
                v-for="service in slotsData?.bookingInfo.services"
                :key="service.id"
              >
                <div class="spi-name">
                  {{ service.service }}
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <b-button
              class="add-points-button"
              style="
                background-color: #f44336 !important;
                border-color: transparent;
              "
              @click="cancelApp"
            >
              <span>{{ $t('Cancel Appointment') }}</span>
            </b-button>
            <b-button
              class="add-points-button"
              style="
                background-color: #04bb03 !important;
                border-color: transparent;
              "
              @click="addPoints"
            >
              <span>{{ $t('Add Points') }}</span>
            </b-button>
          </div>
        </b-modal>
        <b-modal
          :id="'modal-money'"
          title="Expense Tracker"
          hide-footer
          size="lg"
          centered
        >
          <div class="container tracker">
            <div class="header">
              <img
                src="https://i.ibb.co/jfScDTC/budget.png"
                alt="Expense Tracker"
              />
              <div class="balance-container">
                <p class="first">Today</p>
                <p id="balance" class="balance">€{{ dailyTotal }}</p>
              </div>
            </div>
            <div class="inc-exp-container">
              <div
                class="in-container"
                style="color: #04bb03; border-right: 1px solid #fff2f4"
              >
                <p class="title">Today</p>
                <p id="money-plus" class="money plus">
                  <b>€{{ dailyTotal }}</b>
                </p>
              </div>
              <div
                class="inc-container"
                style="color: #f44336; margin-left: 12px"
              >
                <p class="title">Monthly</p>
                <p id="money-minus" class="money minus">
                  <b>€{{ monthlyTotal }}</b>
                </p>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <b-button
              class="add-points-button"
              style="background-color: #04bb03 !important"
              @click="closeMoney()"
            >
              <span>{{ $t('Close') }}</span>
            </b-button>
          </div>
        </b-modal>
      </div>
      <div class="account-selection">
        <router-link :to="{ path: '/' }">
          <font-awesome-icon icon="home" />
        </router-link>

        <!-- Worker Switcher -->
        <div class="worker-switcher" style="width: 100%">
          <b-button-group class="float-right">
            <b-button
              :variant="selectedWorker === 'Ridir' ? 'primary' : 'secondary'"
              @click="switchWorker('Ridir')"
              >Ridir</b-button
            >
            <b-button
              :variant="selectedWorker === 'Maan' ? 'primary' : 'secondary'"
              @click="switchWorker('Maan')"
              >Maan</b-button
            >
          </b-button-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import CalendarMonthDayItem from './CalendarMonthDayItem';
import CalendarDateIndicator from './CalendarDateIndicator';
import CalendarDateSelector from './CalendarDateSelector';
import CalendarWeekdays from './CalendarWeekdays';
import TimeslotSelector from './TimeslotSelector';

dayjs.extend(weekday);
dayjs.extend(weekOfYear);

export default {
  name: 'CalendarMonth',
  components: {
    CalendarMonthDayItem,
    CalendarDateIndicator,
    CalendarDateSelector,
    CalendarWeekdays,
    TimeslotSelector,
  },
  data() {
    return {
      selectedWorker: 'Ridir',
      selectedDate: dayjs(),
      slotsData: null, // Initialize slotsData as null
      selectedDayTimeslots: [],
      calendarDays: [],
      isEventListExpanded: true, // Initial state is expanded
      selectedDayTimeslotsLength: 0, // Add this new line
      dailyTotal: 0, // Add this new line
      monthlyTotal: 0, // Add this new line
    };
  },
  computed: {
    days() {
      return [
        ...this.previousMonthDays,
        ...this.currentMonthDays,
        ...this.nextMonthDays,
      ];
    },
    today() {
      return dayjs(this.selectedDate).format('YYYY-MM-DD');
    },
    month() {
      return Number(this.selectedDate.format('M'));
    },
    year() {
      return Number(this.selectedDate.format('YYYY'));
    },
    numberOfDaysInMonth() {
      return dayjs(this.selectedDate).daysInMonth();
    },
    currentMonthDays() {
      const days = [];
      for (let i = 1; i <= this.numberOfDaysInMonth; i++) {
        const date = dayjs(`${this.year}-${this.month}-${i}`);
        let isCurrentMonth = true;
        let workingHours = { start: '09:00', end: '19:00' };

        if (date.day() === 0) {
          workingHours = { start: '09:00', end: '16:00' };
        } else if (date.day() === 1) {
          isCurrentMonth = false;
        }

        days.push({
          date: date.format('YYYY-MM-DD'),
          isCurrentMonth,
          workingHours,
        });
      }
      return days;
    },
    previousMonthDays() {
      const firstDayOfTheMonthWeekday = this.getWeekday(
        this.currentMonthDays[0]?.date ||
          this.selectedDate.format('YYYY-MM-DD'),
      );
      const previousMonth = dayjs(this.selectedDate).subtract(1, 'month');

      const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday
        ? firstDayOfTheMonthWeekday - 1
        : 6;

      const previousMonthLastMondayDayOfMonth = dayjs(
        this.currentMonthDays[0]?.date ||
          this.selectedDate.format('YYYY-MM-DD'),
      )
        .subtract(visibleNumberOfDaysFromPreviousMonth, 'day')
        .date();

      return [...Array(visibleNumberOfDaysFromPreviousMonth)].map(
        (day, index) => {
          return {
            date: dayjs(
              `${previousMonth.year()}-${previousMonth.month() + 1}-${
                previousMonthLastMondayDayOfMonth + index
              }`,
            ).format('YYYY-MM-DD'),
            isCurrentMonth: false,
          };
        },
      );
    },
    nextMonthDays() {
      const lastDayOfTheMonthWeekday = this.getWeekday(
        `${this.year}-${this.month}-${this.currentMonthDays.length}`,
      );

      const nextMonth = dayjs(`${this.year}-${this.month}-01`).add(1, 'month');

      const visibleNumberOfDaysFromNextMonth = lastDayOfTheMonthWeekday
        ? 7 - lastDayOfTheMonthWeekday
        : lastDayOfTheMonthWeekday;

      return [...Array(visibleNumberOfDaysFromNextMonth)].map((day, index) => {
        return {
          date: dayjs(
            `${nextMonth.year()}-${nextMonth.month() + 1}-${index + 1}`,
          ).format('YYYY-MM-DD'),
          isCurrentMonth: false,
        };
      });
    },
    bookedTimeslots() {
      // Filter the selectedDayTimeslots to show only the booked timeslots
      return this.selectedDayTimeslots.filter((timeslot) => timeslot.booked);
    },
    filteredTimeslots() {
      // Updated to directly use the selectedDayTimeslots
      return this.selectedDayTimeslots;
    },
    getClientName() {
      return this.slotsData?.bookingInfo?.client.name;
    },
    getClientLastName() {
      return this.slotsData?.bookingInfo?.client?.lastName;
    },
    getClientEmail() {
      return this.slotsData?.bookingInfo?.client.email;
    },
    getClientPhone() {
      return this.slotsData?.bookingInfo?.client.phone;
    },
    nextEventTime() {
      const now = dayjs();
      const today = now.format('YYYY-MM-DD');
      const currentTime = now.format('HH:mm');

      // Filter timeslots for today or future dates
      const futureTimeslots = this.selectedDayTimeslots.filter((timeslot) => {
        const timeslotDate = dayjs(timeslot.date); // Assuming your timeslot objects have a date property
        return (
          timeslotDate.isAfter(today, 'day') ||
          (timeslotDate.isSame(today, 'day') && timeslot.time > currentTime)
        );
      });

      // Sort these timeslots by date and time
      const sortedFutureTimeslots = futureTimeslots.sort((a, b) => {
        const dateComparison = dayjs(a.date).diff(dayjs(b.date));
        if (dateComparison !== 0) return dateComparison;
        return a.time.localeCompare(b.time); // Assuming your timeslot objects have a time property in 'HH:mm' format
      });

      // Return the next timeslot's time or a placeholder if there are no future timeslots
      if (sortedFutureTimeslots.length > 0) {
        const nextTimeslot = sortedFutureTimeslots[0];
        return `Volgende : ${nextTimeslot.time}`;
      } else {
        return 'No upcoming events';
      }
    },
  },
  async mounted() {
    this.selectedWorker = this.$route.params.agent;
    const today = dayjs().format('YYYY-MM-DD');
    await this.fetchDataForDay(today); // This call will set the initial length
    await this.watchForChanges(); // Start watching for changes
  },
  methods: {
    toggleEventList() {
      this.isEventListExpanded = !this.isEventListExpanded;
    },
    async calculateIncome() {
      this.$bvModal.show('modal-money');
      try {
        const formattedDate = this.selectedDate.format('YYYY-MM-DD');
        const worker = this.selectedWorker;

        // Fetch timeslots for the selected day
        const dayResponse = await axios.get(
          `https://tessenderlobarbershop-d74df-default-rtdb.europe-west1.firebasedatabase.app/${worker}/calendar/${formattedDate}.json`,
        );
        const dayData = dayResponse.data || {};
        const dayTimeslots = Object.values(dayData);

        // Calculate daily total
        let dailyTotal = 0;
        dayTimeslots.forEach((timeslot) => {
          if (timeslot?.services && timeslot.services.length > 0) {
            timeslot.services.forEach((service) => {
              dailyTotal += parseInt(service.price); // Assuming price is a string, parse it to integer
            });
          }
        });

        // Fetch timeslots for the entire month
        const monthStart = this.selectedDate.startOf('month');
        const monthEnd = this.selectedDate.endOf('month');
        let monthlyTotal = 0;
        for (
          let date = monthStart;
          date.isBefore(monthEnd);
          date = date.add(1, 'day')
        ) {
          const formattedDate = date.format('YYYY-MM-DD');
          const monthResponse = await axios.get(
            `https://tessenderlobarbershop-d74df-default-rtdb.europe-west1.firebasedatabase.app/${worker}/calendar/${formattedDate}.json`,
          );
          const monthData = monthResponse.data || {};
          const monthTimeslots = Object.values(monthData);

          monthTimeslots.forEach((timeslot) => {
            if (timeslot?.services && timeslot.services.length > 0) {
              timeslot.services.forEach((service) => {
                monthlyTotal += parseInt(service.price); // Assuming price is a string, parse it to integer
              });
            }
          });
        }

        this.dailyTotal = dailyTotal;
        this.monthlyTotal = monthlyTotal;
      } catch (error) {
        console.error('Error calculating income:', error);
      }
    },
    closeMoney() {
      this.$bvModal.hide('modal-money');
    },
    getWeekday(date) {
      return dayjs(date).weekday();
    },
    selectDate(newSelectedDate) {
      // Ensure newSelectedDate is a dayjs object
      this.selectedDate = dayjs(newSelectedDate);
    },
    async selectDay(day) {
      this.selectedDate = dayjs(day.date);
      //await this.$router.push({name: 'Events', params: {date: this.selectedDate.format("YYYY-MM-DD")}});
      await this.fetchDataForDay(day.date);
    },
    async watchForChanges() {
      try {
        // eslint-disable-next-line no-constant-condition
        while (true) {
          await this.fetchDataForDay(this.selectedDate.format('YYYY-MM-DD'));
          await this.sleep(5000); // Wait for 10 seconds before checking again
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchDataForDay(today) {
      try {
        // Fetch new data
        const response = await axios.get(
          `https://tessenderlobarbershop-d74df-default-rtdb.europe-west1.firebasedatabase.app/${this.selectedWorker}/calendar/${today}.json`,
        );
        const dayData = response.data || {};
        const timeslots = JSON.parse(JSON.stringify(Object.entries(dayData)));

        // Process the new data
        const newTimeslots = [];
        for (const [time, bookingInfo] of timeslots) {
          if (bookingInfo?.booked) {
            newTimeslots.push({
              time,
              worker: this.selectedWorker,
              bookingInfo,
            });
          }
        }

        // Compare the new data with the existing data
        const hasChanges = this.haveArraysChanged(
          this.selectedDayTimeslots,
          newTimeslots,
        );

        // Update the data if there are changes
        if (hasChanges) {
          this.selectedDayTimeslots = newTimeslots;
        }
      } catch (error) {
        console.error('Error fetching data for day:', error);
      }
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    haveArraysChanged(arr1, arr2) {
      if (arr1.length !== arr2.length) {
        return true;
      }

      for (let i = 0; i < arr1.length; i++) {
        if (!this.isEqual(arr1[i], arr2[i])) {
          return true;
        }
      }

      return false;
    },
    isEqual(obj1, obj2) {
      // Implement your own deep equality check here
      // This is a simple example, and may not cover all cases
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    },
    async goToService(timeslot) {
      // Set the timeslot data to the data property
      this.slotsData = timeslot;
      // Show the modal
      this.$bvModal.show('modal-details');
    },
    async cancelApp() {
      try {
        const workerName = this.slotsData.worker;
        const formattedDate = this.selectedDate.format('YYYY-MM-DD');
        const timeSlot = this.slotsData.time;

        // Update worker's calendar
        await this.updateCalendarDataInDatabase(
          workerName,
          formattedDate,
          timeSlot,
          null,
        );

        // Update client's booked times
        const clientBookedTimes =
          this.slotsData.bookingInfo.client.bookedTimes || [];
        const updatedBookedTimes = clientBookedTimes.filter(
          (time) => !(time.date === formattedDate && time.time === timeSlot),
        );
        updatedBookedTimes.booked = false;

        this.$bvModal.hide('modal-details');
      } catch (error) {
        console.error('Error canceling appointment:', error);
      }
    },
    async updateCalendarDataInDatabase(
      workerName,
      formattedDate,
      timeSlot,
      newData,
    ) {
      console.log(workerName);
      console.log(formattedDate);
      console.log(timeSlot);
      console.log(newData);
      try {
        const databaseURL = `https://tessenderlobarbershop-d74df-default-rtdb.europe-west1.firebasedatabase.app/${workerName}/calendar/${formattedDate}/${timeSlot}.json`;
        if (newData) {
          // Update the calendar data
          await axios.patch(databaseURL, newData);
        } else {
          // Remove the time slot from the calendar
          await axios.delete(databaseURL);
        }
      } catch (error) {
        console.error('Error updating calendar data:', error);
        throw error;
      }
    },
    async scrollToEvent(timeslot) {
      // Remove highlight class from all elements
      const highlightedElements = document.querySelectorAll('.highlight');
      highlightedElements.forEach((element) => {
        element.classList.remove('highlight');
      });

      const elementId = timeslot.replace(':', '-');
      const element = document.getElementById(elementId);

      if (element) {
        // Scroll to the element
        element.scrollIntoView({ behavior: 'smooth' });

        // Add highlight class to animate
        element.classList.add('highlight');
      }
    },
    switchWorker(worker) {
      this.selectedWorker = worker;
      const today = dayjs().format('YYYY-MM-DD');
      this.fetchDataForDay(today);
    },
  },
};
</script>

<style scoped lang="scss">
.money-bill {
  color: #04bb03;
}

.highlight {
  border-left: 8px solid #4169e1 !important; /* Highlight color */
  box-shadow: 0 6px 12px rgba(65, 105, 225, 0.5) !important; /* New box-shadow color */
  transition: border-left-color 0.5s ease, box-shadow 0.5s ease !important; /* Transition effect */

  .events__tag {
    background-color: #4169e1; /* Background color for events__tag */
  }
}

.account-selection {
  display: flex;
  align-items: center;

  > a {
    font-size: 1.3rem;
    color: #caa569 !important;
    border-bottom: 1px dotted;
  }
}

.calendar-container {
  min-height: 100vh;
}

@media (max-width: 281px) {
  .calendar-month {
    width: 60vh;
  }
  .calendar-container {
    height: 138vh;
  }
}

.no-event {
  font-size: clamp(16.834px, 1.052rem + ((1vw - 3.2px) * 0.955), 26px);
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  text-decoration: none;
  text-transform: uppercase;
  color: #888888;
}
//calendar
.calendar-table__body {
  display: flex;
  flex-wrap: wrap;
}
.calendar-container {
  background-color: #fff;
  padding: 16px;
  margin-bottom: 24px;
}
.calendar-container__header {
  display: flex;
  justify-content: space-between;
}
.calendar-container__btn {
  background: transparent;
  border: 0;
  cursor: pointer;
  font-size: 16px;
  outline: none;
  color: #e9e8e8;
}
.calendar-container__btn:hover,
.calendar-container__btn:focus {
  color: #9faab7;
  transition: all 0.3s ease-in;
}
.calendar-container__title {
  color: #222741;
  font-size: 20px;
  font-weight: 700;
}
.calendar-table {
  width: 100%;
}
.calendar-table__item {
  border: 2px solid transparent;
  border-radius: 50%;
  color: #424588;
  font-size: 12px;
  font-weight: 700;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.calendar-table__item:hover {
  background: #f8fafa;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  transition: 0.2s all ease-in;
}
.calendar-table__row {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 4px;
}
.calendar-table__header {
  border-bottom: 2px solid #f2f6f8;
  margin-bottom: 4px;
}
.calendar-table__header .calendar-table__col {
  display: inline-block;
  color: #99a4ae;
  font-size: 12px;
  font-weight: 700;
  padding: 12px 3px;
  text-align: center;
  text-transform: uppercase;
  width: 40px;
  height: 38px;
}
@media (min-width: 360px) {
  .calendar-table__header .calendar-table__col {
    width: 46px;
  }
}
@media (min-width: 410px) {
  .calendar-table__header .calendar-table__col {
    width: 54px;
  }
}
.calendar-table__body .calendar-table__col {
  width: 44px;
  height: 42px;
  padding-bottom: 2px;
}
@media (min-width: 360px) {
  .calendar-table__body .calendar-table__col {
    width: 45px;
    height: 48px;
  }
}
@media (min-width: 410px) {
  .calendar-table__body .calendar-table__col {
    width: 52px;
    height: 56px;
  }
}
.calendar-table__today .calendar-table__item {
  border-color: #fefefe;
  background-color: #f2f6f8;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}
.calendar-table__event .calendar-table__item {
  background-color: #caa569;
  border-color: #fefefe;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  color: #fff;
}
.calendar-table__event--long {
  overflow-x: hidden;
}
.calendar-table__event--long .calendar-table__item {
  border-radius: 0;
  border-width: 2px 0;
}
.calendar-table__event--start .calendar-table__item {
  border-left: 2px solid #fff;
  border-radius: 50% 0 0 50%;
}
.calendar-table__event--start.calendar-table__col:last-child
  .calendar-table__item {
  border-width: 2px;
}
.calendar-table__event--end .calendar-table__item {
  border-right: 2px solid #fff;
  border-radius: 0 50% 50% 0;
}
.calendar-table__event--end.calendar-table__col:first-child
  .calendar-table__item {
  border-width: 2px;
}
.calendar-table__inactive .calendar-table__item {
  color: #dcdce3;
  cursor: default;
}
.calendar-table__inactive .calendar-table__item:hover {
  background: transparent;
  box-shadow: none;
}
.calendar-table__inactive.calendar-table__event .calendar-table__item {
  color: #fff;
  opacity: 0.25;
}
.calendar-table__inactive.calendar-table__event .calendar-table__item:hover {
  background: #caa569;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.head {
  margin-bottom: -3.2em;
}

.top-line {
  font-family: Lobster, sans-serif;
  font-weight: 400;
  font-size: 3.5em;
  text-transform: capitalize;
  text-shadow: 3px 3px #6d4c41;
  color: #caa569;
}

.spacer {
  border-bottom: 1px solid rgb(229, 229, 229);
}

.events-container {
  background-color: #f8fafa;
  padding: 1rem 1.2rem 1rem 1.2rem;
  margin-bottom: 2rem;
  margin-top: 0.5rem;
}

.events__title {
  color: #bec1ca;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 16px;
  width: 100%;
}
.events__tag {
  background: #e42806;
  border: 2px solid #fefefe;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  width: 100px;
  margin-left: 16px;
  padding: 10px 0;
  text-align: center;
}
.events__tag--highlighted {
  background: #fdca40;
}
.events__item {
  background: #fff;
  border-left: 8px solid #e42806;
  border-radius: 2px;
  -moz-box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05);
  padding: 15px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.events__item--left {
  width: calc(100% - 76px);
}
.events__name {
  font-size: 12px;
  font-weight: 700;
  color: #222741;
  display: block;
  margin-bottom: 6px;
}
.events__date {
  font-size: 12px;
  color: #9faab7;
  display: inline-block;
}
.events__list {
  /* Add overflow-y: auto to enable vertical scrolling */
  overflow-y: auto;
  /* Set a fixed height for the list to enable scrolling */
  max-height: 200px; /* Adjust the height as needed */
}

.events__item.selected {
  /* Add some visual indication for the selected event */
  background-color: #f0f0f0; /* Change the background color as needed */
}

.date-container {
  display: inline-block;
  width: 7.5em;
  padding-left: 13px;
  padding-right: 8px;
  padding-bottom: 2px;
  margin-right: 6px;
  font-family: Oswald, sans-serif;
  font-weight: 700;
  color: #424588;
  text-align: center;
  line-height: 25px;
}

.date {
  font-size: 1.55em;
}

.month {
  display: block;
  font-size: 0.4em;
  font-weight: 400;
  margin-top: -0.8em;
  text-transform: uppercase;
}

.detail-container {
  display: inline-block;
  vertical-align: top;
  padding-top: 3px;
}

.title {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, sans-serif !important;
  font-weight: 900 !important;
  font-size: 21px !important;
}

.description {
  display: block;
  font-size: 0.9em;
  color: #424588;
}

.services {
  list-style: circle;
  margin-left: 20px;
  > li {
    color: #424588 !important;
    font-size: 10px;
    font-weight: 700;
  }
}

.event-title {
  font-style: normal;
  font-weight: 900;
  letter-spacing: 0.08em;
  line-height: 1.4;
  text-decoration: none;
  color: #caa569;
  padding: 2.2rem 0 0.5rem 0rem;
  font-size: 1.5rem;
  border-top: 1px solid #dcdce3;
}

.os-summary-contents {
  padding: 25px 30px 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.summary-box.main-box {
  padding-bottom: 10px;
  border-bottom: 1px solid #f1f1f1;
}

.summary-attributes {
  padding: 10px;
  background-color: #f7f7f7;
  color: #8e97b3;
  font-size: 12px;
  margin-top: 10px;
}

.summary-boxes-columns {
  display: block;
}

.summary-attributes {
  padding: 10px;
  background-color: #f7f7f7;
  color: #8e97b3;
  font-size: 12px;
  margin-top: 10px;
}

.summary-boxes-columns {
  display: block;
}

.price-breakdown-w {
  padding: 25px 30px 30px;
  margin: 20px -30px -30px;
  border-top: 1px solid #eee;
}

.price-breakdown-w .pb-heading {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 5px;
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 11px;
  font-weight: 500;
  color: #8e97b3;
}

.price-breakdown-w .summary-price-item-w {
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
}

.breakdown-w .summary-box-heading {
  display: none;
}

.price-breakdown-w .summary-price-item-w {
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
}

.breakdown-w .summary-box-heading {
  display: none;
}

.latepoint-w .latepoint-summary-w .summary-price-item-w {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.latepoint-step-content {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  color: #8e97b3;
  min-height: 200px;
  text-align: left;
  transition: all 0.3s cubic-bezier(0.25, 1.15, 0.5, 1.2);
  opacity: 1;
  transform: translateY(0);
}

.summary-price-item-w .spi-name,
.summary-price-item-w .spi-price {
  color: #1f222b;
  font-weight: 400;
  font-size: 10px;
}

.summary-price-item-w .spi-price {
  color: #1f222b;
  font-weight: 800;
}

.inline-avatar {
  display: inline-block;
  position: relative;
  top: 10px;
  .os-avatar-small {
    width: 55px;
    height: 55px;
    display: inline-block;
    background-size: cover;
    background-position: center center;
    margin: 0 1rem 0rem 0rem;
    position: relative;
    top: -6px;
    border-radius: 50%;
  }
  .agent-container {
    display: inline-block;
    line-height: 20px;
    position: relative;
    top: -20px;
    .agent {
      display: block;
    }
  }
}

#modal-money {
  font-family: Arial, sans-serif;
}

#modal-money .modal-content {
  background-color: #f8f9fa; /* Light gray background */
  color: #333; /* Dark text color */
}

#modal-money .modal-body {
  padding: 20px;
}

#modal-money .modal-footer {
  justify-content: center; /* Center align footer content */
  padding: 10px;
}

#modal-money .modal-footer .add-points-button {
  color: #fff; /* Text color */
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  border-color: transparent !important;
}

#modal-money .modal-footer .add-points-button:hover {
  background-color: #0c8e3e; /* Dark green on hover */
}

.tracker {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .header img {
    width: 4.5rem;
    width: 4.5rem;
    opacity: 0.5;
  }

  .balance-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    line-height: 5px;

    .first {
      font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI,
        Roboto, Helvetica Neue, Arial, sans-serif !important;
      font-weight: 900 !important;
      font-size: 21px !important;
      letter-spacing: 0.08em;
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
    }
  }

  .container {
    margin: 30px auto;
    width: 350px;
  }

  h1 {
    font-size: 1.25rem;
  }

  h2 {
    font-size: 1.17rem;
  }

  .balance {
    letter-spacing: 1px;
    margin: 0;
    font-size: 2rem;
    font-weight: 900;
  }

  h3 {
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 10px;
    margin: 40px 0 10px;
  }

  h2,
  h4 {
    margin: 0;
    text-transform: uppercase;
  }

  .inc-exp-container {
    .title {
      font-size: clamp(16.834px, 1.052rem + (1vw - 3.2px) * 0.955, 26px);
      font-style: normal;
      font-weight: 900;
      letter-spacing: 0.05em;
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
    }
  }

  .inc-exp-container {
    background-color: #e5e6ed;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 20px;
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
    border-radius: 5px;
  }

  .inc-exp-container > div {
    flex: 1;
    text-align: center;
  }

  .inc-exp-container > div:first-of-type {
    border-right: 1px solid var(--border-secondary-color);
  }

  .money {
    font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto,
      Helvetica Neue, Arial, sans-serif !important;
    font-weight: 900 !important;
    font-size: 21px !important;
    letter-spacing: 1px;
    margin: 5px 0;
  }

  label {
    display: inline-block;
    margin: 10px 0;
  }
}
</style>
