<template>
  <ol class="day-of-week">
    <li v-for="weekday in weekdays" :key="weekday">{{ weekday }}</li>
  </ol>
</template>

<script>
const WEEKDAYS = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

export default {
  name: "CalendarWeekdays",

  computed: {
    weekdays() {
      return WEEKDAYS;
    }
  }
};
</script>

<style scoped>
.day-of-week {
  display: grid;
  grid-template-columns: repeat(7, 1fr); /* Creates a 10-column grid */
  gap: 0; /* Adjust the gap between items as needed */
  border-bottom: 2px solid #F2F6F8;
  margin-bottom: 4px;
}

.day-of-week > * {
  display: flex;
  justify-content: center;
  color: #99A4AE;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  width: 55px;
  height: 40px;
}

@media (min-width: 360px) {
  .day-of-week > * {
    width: 46px;
    height: 32px;
  }
}
@media (min-width: 410px) {
  .day-of-week > * {
    width: 54px;
    height: 56px;
  }
}

/* Styles for tablets */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* Your tablet-specific styles go here */
  .day-of-week > * {
    font-size: 18px;
  }
}


@media (max-width: 390px) {
  .day-of-week > * {
    font-size: 12px;
  }
}

</style>
