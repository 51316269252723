<template>
  <div class="calendar-month">
    <div class="calendar-month-header">
      <CalendarDateIndicator
        :selected-date="selectedDate"
        class="calendar-month-header-selected-month"
      />
      <CalendarDateSelector
        :current-date="today"
        :selected-date="selectedDate"
        @dateSelected="selectDate"
      />
    </div>
    <CalendarWeekdays />
    <ol class="days-grid">
      <CalendarMonthDayItem
        v-for="day in days"
        :key="day.date"
        :day="day"
        :selectedAgent="selectedWorker"
        :is-today="day.date === today"
        @click="selectDay(day)"
      />
    </ol>
    <TimeslotSelector
      v-if="selectedDate && isSelected"
      :selectedDate="selectedDate"
      :selectedWorker="this.selectedWorker"
      :selectedServiceArr="this.selectedServiceArr"
      :selectedServices="this.selectedServices"
      :workingHours="selectedDate.workingHours"
      :generatedTimeslots="generatedTimeslots"
      @timePicked="handleTimePicked"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import CalendarMonthDayItem from './CalendarMonthDayItem';
import CalendarDateIndicator from './CalendarDateIndicator';
import CalendarDateSelector from './CalendarDateSelector';
import CalendarWeekdays from './CalendarWeekdays';
import TimeslotSelector from './TimeslotSelector';

dayjs.extend(weekday);
dayjs.extend(weekOfYear);

export default {
  name: 'CalendarMonth',
  components: {
    CalendarMonthDayItem,
    CalendarDateIndicator,
    CalendarDateSelector,
    CalendarWeekdays,
    TimeslotSelector,
  },
  data() {
    return {
      selectedDate: dayjs(),
      selectedDayTimeslots: [],
      isSelected: false,
    };
  },
  props: ['selectedWorker', 'selectedServices', 'selectedServiceArr'],
  computed: {
    days() {
      return [
        ...this.previousMonthDays,
        ...this.currentMonthDays,
        ...this.nextMonthDays,
      ];
    },
    today() {
      return dayjs(this.selectedDate).format('YYYY-MM-DD');
    },
    month() {
      return Number(this.selectedDate.format('M'));
    },
    year() {
      return Number(this.selectedDate.format('YYYY'));
    },
    numberOfDaysInMonth() {
      return dayjs(this.selectedDate).daysInMonth();
    },
    currentMonthDays() {
      const days = [];
      for (let i = 1; i <= this.numberOfDaysInMonth; i++) {
        const date = dayjs(`${this.year}-${this.month}-${i}`);
        let isCurrentMonth = true;
        let workingHours = { start: '09:00', end: '19:00' };

        if (date.day() === 0) {
          workingHours = { start: '09:00', end: '16:00' };
        } else if (date.day() === 1) {
          isCurrentMonth = false;
        }

        days.push({
          date: date.format('YYYY-MM-DD'),
          isCurrentMonth,
          workingHours,
        });
      }
      return days;
    },
    previousMonthDays() {
      const firstDayOfTheMonthWeekday = this.getWeekday(
        this.currentMonthDays[0]?.date ||
          this.selectedDate.format('YYYY-MM-DD'),
      );
      const previousMonth = dayjs(this.selectedDate).subtract(1, 'month');

      const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday
        ? firstDayOfTheMonthWeekday - 1
        : 6;

      const previousMonthLastMondayDayOfMonth = dayjs(
        this.currentMonthDays[0]?.date ||
          this.selectedDate.format('YYYY-MM-DD'),
      )
        .subtract(visibleNumberOfDaysFromPreviousMonth, 'day')
        .date();

      return [...Array(visibleNumberOfDaysFromPreviousMonth)].map(
        (day, index) => {
          return {
            date: dayjs(
              `${previousMonth.year()}-${previousMonth.month() + 1}-${
                previousMonthLastMondayDayOfMonth + index
              }`,
            ).format('YYYY-MM-DD'),
            isCurrentMonth: false,
          };
        },
      );
    },
    nextMonthDays() {
      const lastDayOfTheMonthWeekday = this.getWeekday(
        `${this.year}-${this.month}-${this.currentMonthDays.length}`,
      );

      const nextMonth = dayjs(`${this.year}-${this.month}-01`).add(1, 'month');

      const visibleNumberOfDaysFromNextMonth = lastDayOfTheMonthWeekday
        ? 7 - lastDayOfTheMonthWeekday
        : lastDayOfTheMonthWeekday;

      return [...Array(visibleNumberOfDaysFromNextMonth)].map((day, index) => {
        return {
          date: dayjs(
            `${nextMonth.year()}-${nextMonth.month() + 1}-${index + 1}`,
          ).format('YYYY-MM-DD'),
          isCurrentMonth: false,
        };
      });
    },
    generatedTimeslots() {
      const timeslots = [];

      if (this.selectedDayTimeslots.length > 0) {
        this.selectedDayTimeslots.forEach((timeslot) => {
          timeslots.push({
            start: timeslot.start.format('HH:mm'),
            end: timeslot.end.format('HH:mm'),
          });
        });
      }

      return timeslots;
    },
  },
  watch: {
    selectedDate: {
      handler(newDate) {
        console.log('Selected Date Updated:', newDate);
      },
      deep: true,
    },
    selectedDayTimeslots: {
      handler(newTimeslots) {
        console.log('Selected', newTimeslots);
      },
      deep: true,
    },
  },
  methods: {
    getWeekday(date) {
      return dayjs(date).weekday();
    },
    selectDate(newSelectedDate) {
      // Ensure newSelectedDate is a dayjs object
      this.selectedDate = dayjs(newSelectedDate);
    },
    selectDay(day) {
      this.isSelected = true;
      this.selectedDate = dayjs(day.date);
      this.generateTimeslots(day.workingHours);
    },
    emitCalendarData() {
      this.$emit('timePicked', {
        selectedDate: this.selectedDate,
        updatedTime: this.updatedTime ? this.updatedTime : '9:00',
      });
    },
    generateTimeslots(workingHours) {
      const startTime = dayjs(workingHours.start, 'HH:mm');
      const endTime = dayjs(workingHours.end, 'HH:mm');
      const interval = 15;
      let currentTime = startTime;
      const timeslots = [];

      while (currentTime.isBefore(endTime)) {
        timeslots.push({
          start: currentTime.format('HH:mm'),
          end: currentTime.add(interval, 'minutes').format('HH:mm'),
        });
        currentTime = currentTime.add(interval, 'minutes');
      }
      this.selectedDayTimeslots = timeslots;
    },
    dateSelected(newSelectedDate) {
      // Assuming updatedTime is set to 9:00 by default
      const updatedTime = dayjs(newSelectedDate)
        .hour(9)
        .minute(0)
        .format('HH:mm');
      this.$emit('dateSelected', {
        selectedDate: newSelectedDate,
        updatedTime,
      });
    },
    handleTimePicked({ selectedDate, updatedTime }) {
      // Call the emitCalendarData method before the component is destroyed
      this.emitCalendarData();
      // You can now use selectedDate and updatedTime in your logic
      this.selectedDate = selectedDate;
      this.updatedTime = updatedTime;
    },
  },
  beforeDestroy() {
    // Call the emitCalendarData method before the component is destroyed
    this.emitCalendarData();
  },
};
</script>

<style scoped>
.calendar-month-header {
  max-height: 55px;
}

.calendar-month {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  position: relative;
  min-height: 65vh;
  background-color: #ffffff;
}

.days-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  list-style-type: none;
}

.day-of-week > * {
  text-align: right;
  padding-right: 5px;
}

.days-grid {
  height: 100%;
  position: relative;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
}

@media (max-width: 360px) {
  .calendar-month-header {
    max-height: 40px;
  }
}
</style>
