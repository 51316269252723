<template>
  <div class="calendar-month">
    <div class="calendar-container">
      <div class="details">
        <div class="bg-brand300 p-4 p-md-5 overflow-auto">
          <div class="filter-buttons" style="min-height: 70px">
            <div class="filter-back">
              <router-link :to="'/calendar/'">
                <font-awesome-icon icon="arrow-left" />
              </router-link>
            </div>
            <div
              class="inline-avatar"
              style="
                display: inline-block;
                position: absolute;
                top: 45px;
                width: 60%;
              "
            >
              <h1 class="date-title">{{ dateUrl }}</h1>
              <!--div class="os-avatar-small"
                   @click="filterByWorker(null)"
                   style="background-image: url(https://i.ibb.co/NZDBJk1/all-filter.png);">
              </div-->
            </div>
          </div>
          <div
            class="events-container"
            v-if="filteredTimeslots && filteredTimeslots.length > 0"
          >
            <ul class="events__list">
              <li
                v-for="(timeslot, index) in filteredTimeslots"
                :key="index"
                class="events__item"
                :class="timeslot.worker"
                @click="goToService(timeslot)"
              >
                <div
                  class="events__item--left"
                  v-if="timeslot?.bookingInfo?.client"
                >
                  <span class="events__name">{{
                    timeslot?.bookingInfo?.client?.name +
                    ' ' +
                    ' ' +
                    timeslot?.bookingInfo?.client?.lastName
                  }}</span>
                  <span
                    class="events__date"
                    v-if="timeslot?.bookingInfo?.client?.phone"
                    >{{ timeslot?.bookingInfo.client?.phone }}</span
                  >
                  <span
                    class="events__date"
                    v-if="timeslot?.bookingInfo?.client?.email"
                    >{{ timeslot?.bookingInfo.client?.email }}</span
                  ><br />
                </div>
                <span class="events__tag" :class="timeslot.worker">{{
                  timeslot.time
                }}</span>
              </li>
            </ul>
            <!-- Modal for Service Selection -->
            <b-modal
              :id="'modal-details'"
              title="Summary"
              hide-footer
              size="lg"
              centered
            >
              <div
                class="os-summary-contents"
                style="padding: 0 30px 30px !important"
              >
                <div class="booking-summary-info-w">
                  <div class="summary-box main-box">
                    <div class="summary-attributes sa-clean">
                      <div class="inline-avatar">
                        <div
                          class="os-avatar-small"
                          v-if="slotsData?.worker === 'Ridir'"
                          style="
                            background-image: url(https://ccmihai.github.io/portfolio-template/images/header/me.png);
                          "
                        ></div>
                        <div
                          class="os-avatar-small"
                          v-if="slotsData?.worker === 'Maan'"
                          style="
                            background-image: url(https://qph.cf2.quoracdn.net/main-thumb-487762713-200-ldqifdohlrsonsomzyrjhrtnvlrjezxp.jpeg);
                          "
                        ></div>
                        <div class="agent-container">
                          <span class="agent" v-if="slotsData?.worker"
                            >{{ $t('Agent') }}:
                            <strong>{{ slotsData?.worker }}</strong></span
                          >
                          <span class="agent" v-if="dateUrl"
                            >{{ $t('Date') }}:
                            <strong>{{ dateUrl }}</strong></span
                          >
                          <span class="agent" v-if="slotsData?.time"
                            >{{ $t('Time') }}:
                            <strong>{{ slotsData?.time }}</strong></span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="price-breakdown-w">
                  <div class="pb-heading">
                    {{ $t('Customer Informations') }}
                  </div>
                  <div class="summary-price-item-w">
                    <div class="spi-name">
                      {{ getClientName }}
                      <span class="ml-1">{{ getClientLastName }}</span>
                    </div>
                  </div>
                  <div class="summary-price-item-w" v-if="getClientEmail">
                    <div class="spi-name">{{ getClientEmail }}</div>
                  </div>
                  <div class="summary-price-item-w" v-if="getClientPhone">
                    <div class="spi-name">{{ getClientPhone }}</div>
                  </div>
                </div>
                <div class="price-breakdown-w">
                  <div class="pb-heading">{{ $t('Services') }}</div>
                  <div
                    class="summary-price-item-w"
                    v-for="service in slotsData?.bookingInfo.services"
                    :key="service.id"
                  >
                    <div class="spi-name">
                      {{ service.service }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <b-button
                  class="add-points-button"
                  style="background-color: #f44336 !important"
                  @click="cancelApp"
                >
                  <span>{{ $t('Cancel Appointment') }}</span>
                </b-button>
              </div>
            </b-modal>
          </div>
          <div class="events-container" v-else>
            <p class="no-event">There are no events today.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';

dayjs.extend(weekday);
dayjs.extend(weekOfYear);

export default {
  name: 'Events',
  data() {
    return {
      dateUrl: null,
      selectedDate: '',
      filteredWorker: null, // Track the filtered worker
      selectedDayTimeslots: [],
      calendarDays: [],
      slotsData: null, // Initialize slotsData as null
      timeslot: null,
    };
  },
  computed: {
    days() {
      return [
        ...this.previousMonthDays,
        ...this.currentMonthDays,
        ...this.nextMonthDays,
      ];
    },
    bookedTimeslots() {
      // Sort the timeslots by their start time in ascending order
      return this.selectedDayTimeslots
        .filter((timeslot) => timeslot?.bookingInfo.booked)
        .sort((a, b) => {
          // Convert time strings to minutes for comparison
          const getTimeInMinutes = (time) => {
            const [hours, minutes] = time.split(':').map(Number);
            return hours * 60 + minutes;
          };

          return getTimeInMinutes(a.time) - getTimeInMinutes(b.time);
        });
    },
    getClientName() {
      return this.slotsData?.bookingInfo?.client.name;
    },
    getClientLastName() {
      return this.slotsData?.bookingInfo?.client?.lastName;
    },
    getClientEmail() {
      return this.slotsData?.bookingInfo?.client.email;
    },
    getClientPhone() {
      return this.slotsData?.bookingInfo?.client.phone;
    },
    filteredTimeslots() {
      // Updated to directly use the selectedDayTimeslots
      return this.selectedDayTimeslots;
    },
  },
  async created() {
    this.timeslot = this.$parent.$attrs.timeslot;
    this.dateUrl = this.$route.params.date;
    //this.dateUrl = this.$route.params.date.split('-')
    //this.dateUrl = this.dateUrl[2] + '-' + this.dateUrl[1] + '-' + this.dateUrl[0]
  },
  async mounted() {
    this.timeslot = this.$parent.$attrs.timeslot;
    await this.fetchDataForDay(); // Initial call to start watching for changes
  },
  methods: {
    async goToService(timeslot) {
      // Set the timeslot data to the data property
      this.slotsData = timeslot;
      // Show the modal
      this.$bvModal.show('modal-details');
    },
    async fetchDataForDay() {
      try {
        this.formattedDate = dayjs(this.$route.params.date).format(
          'YYYY-MM-DD',
        );
        this.selectedDayTimeslots = [];

        const workerName = this.selectedWorker; // Since you have only one worker now
        const apiUrl = `https://tessenderlobarbershop-d74df-default-rtdb.europe-west1.firebasedatabase.app/${workerName}/calendar/${this.formattedDate}.json`;

        const response = await axios.get(apiUrl);
        const dayData = response.data || {};

        Object.entries(dayData).forEach(([time, bookingInfo]) => {
          const isDuplicate = this.selectedDayTimeslots.some(
            (timeslot) =>
              timeslot.worker === workerName && timeslot.time === time,
          );

          if (!isDuplicate) {
            this.selectedDayTimeslots.push({
              time,
              worker: workerName,
              bookingInfo,
            });
          }
        });
      } catch (error) {
        console.error('Error fetching data for day:', error);
      }
    },
    async cancelApp() {
      try {
        const workerName = this.slotsData.worker;
        const formattedDate = this.selectedDate.format('YYYY-MM-DD');
        const timeSlot = this.slotsData.time;

        // Update worker's calendar
        await this.updateCalendarDataInDatabase(
          workerName,
          formattedDate,
          timeSlot,
          null,
        );

        // Update client's booked times
        const clientBookedTimes =
          this.slotsData.bookingInfo.client.bookedTimes || [];
        const updatedBookedTimes = clientBookedTimes.filter(
          (time) => !(time.date === formattedDate && time.time === timeSlot),
        );
        updatedBookedTimes.booked = false;

        this.$bvModal.hide('modal-details');
      } catch (error) {
        console.error('Error canceling appointment:', error);
      }
    },
    async updateCalendarDataInDatabase(
      workerName,
      formattedDate,
      timeSlot,
      newData,
    ) {
      console.log(workerName);
      console.log(formattedDate);
      console.log(timeSlot);
      console.log(newData);
      try {
        const databaseURL = `https://tessenderlobarbershop-d74df-default-rtdb.europe-west1.firebasedatabase.app/${workerName}/calendar/${formattedDate}/${timeSlot}.json`;
        if (newData) {
          // Update the calendar data
          await axios.patch(databaseURL, newData);
        } else {
          // Remove the time slot from the calendar
          await axios.delete(databaseURL);
        }
      } catch (error) {
        console.error('Error updating calendar data:', error);
        throw error;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.date-title {
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.08em;
  line-height: 1.4;
  text-decoration: none;
  text-transform: uppercase;
  color: #f9f9f9;
  width: 100%;
  text-align: center;
}

.filter-back {
  display: inline-block;
  width: 20%;
  font-size: 1.8rem;
  > a {
    color: #caa569;
  }
}

.no-event {
  font-size: clamp(16.834px, 1.052rem + ((1vw - 3.2px) * 0.955), 26px);
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  text-decoration: none;
  text-transform: uppercase;
  color: #888888;
}

.calendar-container {
  padding: 1rem;
}

.calendar-table__body {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px; /* Reduced gap */
}

.events-container {
  padding: 0 15px;
  margin-top: 8%;
  min-height: 100vh;
}

.events__title {
  color: #bec1ca;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 16px;
}

.events__tag {
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  width: 85px;
  padding: 12px 0;
  text-align: center;
}

.events__tag.Ridir {
  background: #caa569;
  border: 2px solid #fefefe;
}

.events__tag--highlighted {
  background: #fdca40;
}

.events__item {
  background: #fff;
  border-radius: 2px;
  -moz-box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05);
  padding: 15px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
}

.events__item.Ridir {
  border-left: 8px solid #caa569;
}

.events__item--left {
  width: calc(100% - 76px);
}

.events__name {
  font-size: 12px;
  font-weight: 700;
  display: block;
  margin-bottom: 6px;
}

.Ridir {
  .events__item--left {
    .events__name {
      color: #caa569;
    }
  }
}

.events__date {
  font-size: 12px;
  color: #9faab7;
  display: inline-block;
}

.overflow-auto > a {
  color: #caa569 !important;
}

.os-summary-contents {
  padding: 25px 30px 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.summary-box.main-box {
  padding-bottom: 10px;
  border-bottom: 1px solid #f1f1f1;
}

.summary-attributes {
  padding: 10px;
  background-color: #f7f7f7;
  color: #8e97b3;
  font-size: 12px;
  margin-top: 10px;
}

.summary-boxes-columns {
  display: block;
}

.modal-left-side {
  padding: 0 1rem 0 0;
  border-right: 2px solid #e5e7ee; // Separating line between panels
}

@media (max-width: 400px) {
  .modal-left-side {
    padding: 0 0 0 0;
    border-right: none; // Separating line between panels
  }
}

.modal-right-side {
  width: 45%; // Adjust as necessary to control width
  display: flex;
  flex-direction: column;
  justify-content: space-between; // Ensures footer is at the bottom
}

.modal-footer {
  padding: 20px 30px; // Consistent padding with the summary body
  border-top: 1px solid #dee2e6; // Separates footer from content
  background: #f8f9fa; // Matches the summary header
  display: flex;
  justify-content: flex-end;
  > button {
    font-weight: 900;
    box-shadow: none;
    padding: 10px 14px;
    font-size: 12px;
    letter-spacing: 0.5px;
    background-color: #caa583 !important;
    border-radius: 0;
    text-decoration: none !important;
    display: inline-block;
    border: 0;
    line-height: 1.2;
  }
}

.modal-footer-back {
  justify-content: flex-start !important;
}

.modal-header {
  display: flex !important;
  align-items: flex-start !important;
  justify-content: space-between !important;
  padding: 1rem 1rem !important;
  border-bottom: 1px solid #dee2e6 !important;
  border-top-left-radius: calc(0.3rem - 1px) !important;
  border-top-right-radius: calc(0.3rem - 1px) !important;
}

::v-deep .modal-header .modal-title {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, sans-serif !important;
  font-weight: 900 !important;
  font-size: 21px !important;
  color: #caa569 !important;
}

.modal-header .close {
  padding: 1rem 1rem !important;
  margin: -1rem -1rem -1rem auto !important;
}

/* Responsive adjustments */
@media (max-width: 767px) {
  .modal-right-side {
    display: none !important;
  }
}

.summary-attributes {
  padding: 10px;
  background-color: #f7f7f7;
  color: #8e97b3;
  font-size: 12px;
  margin-top: 10px;
}

.summary-boxes-columns {
  display: block;
}

.price-breakdown-w {
  padding: 25px 30px 30px;
  margin: 20px -30px -30px;
  border-top: 1px solid #eee;
}

.price-breakdown-w .pb-heading {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 5px;
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 11px;
  font-weight: 500;
  color: #8e97b3;
}

.price-breakdown-w .summary-price-item-w {
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
}

.breakdown-w .summary-box-heading {
  display: none;
}

.price-breakdown-w .summary-price-item-w {
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
}

.breakdown-w .summary-box-heading {
  display: none;
}

.latepoint-w .latepoint-summary-w .summary-price-item-w {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.latepoint-step-content {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  color: #8e97b3;
  min-height: 200px;
  text-align: left;
  transition: all 0.3s cubic-bezier(0.25, 1.15, 0.5, 1.2);
  opacity: 1;
  transform: translateY(0);
}

.summary-price-item-w .spi-name,
.summary-price-item-w .spi-price {
  color: #1f222b;
  font-weight: 400;
  font-size: 10px;
}

.summary-price-item-w .spi-price {
  color: #1f222b;
  font-weight: 800;
}

.inline-avatar {
  display: inline-block;
  position: relative;
  top: 10px;
  .os-avatar-small {
    width: 55px;
    height: 55px;
    display: inline-block;
    background-size: cover;
    background-position: center center;
    margin: 0 1rem 0rem 0rem;
    position: relative;
    top: -6px;
    border-radius: 50%;
  }
  .agent-container {
    display: inline-block;
    line-height: 20px;
    position: relative;
    top: -20px;
    .agent {
      display: block;
    }
  }
}
</style>
