<template>
  <div class="account">
    <div class="account__content">
      <h1 class="account__title">SIGN IN</h1>
      <form @submit.prevent ref="frm">
        <div class="custom__input">
          <span class = "custom__input-row">
            <input class="field" type="text" v-model="input.username" name="username" autofocus required>
            <span class="placeholder">Username</span>
          </span>
        </div>
        <div class="custom__input">
          <span class="custom__input-row">
            <input id="password" class="field password" type="password"  v-model ="input.password" @blur="hidePassword()" required>
            <span class="placeholder">Password</span>
            <font-awesome-icon :icon="myIcon" class="icon" v-on:click="togglePassword()"/>
          </span>
        </div>
        <span class="warning" v-if="alert">Invalid username or password.</span>
        <input id="submit-btn" type="submit" class="primary-button" value="Sign In" v-on:click="signin($event)"/>
      </form>
      <a class="account__route">Forgot your username or password?</a>
      <router-link :to="{path: 'signup'}" append class="account__route">Don't have an account? Create one now</router-link>
    </div>
  </div>
</template>

<script>
export default {
  title: 'Booking',
  name: 'login',
  data: function(){
    return{
      myIcon: 'eye',
      input: {
        username: "",
        password: "",
      },
      alert: false
    }
  },
  iconEye:{
    icon: ''
  },
  methods:{
      togglePassword: function(){
        var x = document.getElementById("password");
        if(x.type === "password"){
          x.type = "text"
          this.myIcon = 'eye-slash'
        } 
        else{
          x.type = "password"
          this.myIcon = 'eye'
        }  
      },
      hidePassword: function(){
        var x = document.getElementById("password");
        x.type = "password"
        this.myIcon = 'eye'
      },
      //temporary login method for admin (testing)
      signin(e){
        if((this.input.username === "Ridir" || this.input.username === "33333333") && this.input.password === "33333333"){
          this.alert = false
          e.preventDefault()
          this.$store.commit("setAuth",true);
          this.$router.replace(`/calendar/${this.input.username}`);
        } else {
          // HANDLE INVALID AUTHENTICATION
          this.input.username = "";
          this.input.password = "";
          this.alert = true
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
#submit-btn {
  width: 100%;
  outline: inherit;
  border: none;
  text-decoration: none;
  cursor: pointer;
  background: #caa569 !important;
  color: #fff;
  text-transform: capitalize;
  font-weight: 550;
  transition: 0.2s ease;
}

.account{
  display: flex;
  background: #fff;
  padding: .8em 1.2em;
  border: 0px solid #fff;
  min-width: 500px;
  align-items: center;
  height: 100vh;
  border-radius: 0px;

  &__content{
    display: flex;
    flex-direction: column;
    padding: 0em 1.2em;
    width: 350px;
    margin:auto;
    position: relative;
  }

  &__title{
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 5px;
    font-size: 50px;
    margin: 0px 0px 50px 0;
    display: inline;
    margin-inline-start: 0px;
    margin-block-start: 0px;
  }

  &__route{
    margin-top: 14px;
    margin-bottom: -8px;
    cursor: pointer;
  }
}

.primary-button{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin: 20px 0px 20px 0px;
  height: 56px;
  font-size: 18px;
}

@media screen and (max-width:1024px){
  .account{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 602.55px;
    min-width: 400px;
    max-height: 602.55px;
    //   min-height: 602.55px;
    width: 50vw;
    // background:#fff;
    background: $white-opaque;
    backdrop-filter: saturate(180%) blur(20px);
    z-index: 99;
    box-shadow: 0 5px 13px rgba(0,0,0,0.8);
    border-radius: 15px;


    &__route{
      font-size: 13px;
    }

    &__title{
      text-align: center;
    }
  }

  label{
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .login{
    width: 300px;
  }

  .field{
    margin: 10px 0;
  }
}

@media screen and (max-width: 450px) {

  .placeholder{
    font-weight: 400;
    font-size: 16px;
  }

  .account{
    width: 95vw;
    height: auto;
    min-width: 200px;
    padding: 3em 1em;

    &__route{
      font-size: 12px;
      text-align: center;
    }

    &__content {
      padding: 0;
    }

    &__title{
      font-size: 32px;
      margin: 0px 0px 30px 0;
    }

    .field {
      height: 55px;
      font-size: 13px;
      padding: 17px 12px 0px 12px;
    }

    .icon {
      top: 23px
    }
  }

  .primary-button{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin: 20px 0px 20px 0px;
    height: 50px;
    font-size: 1em;
  }
}

.field {
  &:hover{
    background: rgba(126,126,126,0.1);
;
  }
}

.warning{
  // color: $btn-destructive;
  min-width: 240px;
  width: 100%;
  // width: 400px;
  margin: auto;
  margin-top: 1rem;
  padding: 12px 1rem;
  border: 1px solid $input-error;
  background: $secondary-input-error;
  border-radius: 8px;
  display: block;
}

.primary-button{
  text-decoration: none;
  cursor: pointer;
  background: $btn-primary;
  color: $btn-primary-text;
  text-transform: capitalize;
  font-weight: 550;
  transition: .2s ease;

  &:hover{
    background: $btn-primary-hover;
  }

  &:active{
    background: $btn-primary-click;
  }
}

.destructive-button{
  text-decoration: none;
  cursor: pointer;
  background: $notif;
  color: $btn-destructive-text;
  // text-transform: capitalize;
  font-weight: 550;
  transition: .2s ease;

  &:hover{
    background: $btn-destructive-hover;
  }

  &:active{
    background: $btn-destructive-click;
  }
}

.destructive-secondary{
  text-decoration: none;
  cursor: pointer;
  border: 1px solid $notif;
  color: $notif;
  // text-transform: capitalize;
  font-weight: 550;
  transition: .2s ease;

  &:hover{
    border: 1px solid $btn-destructive-hover;
  }

  &:active{
    border: 1px solid $btn-destructive-click;
  }
}

.icon-secondary{
  color: $icon-secondary;
}

.icon-primary{
  color: $icon-primary;
}

.icon-primary--dark{
  color: $icon-primary-dark;
}

.route-menu{
  color: $route-color;

  &:hover{
    color: $route-color-hover;
  }
}

//========================================
//COPY PASTE THIS TO MAKE OUR CUSTOM INPUT
//========================================
/*
<div class="custom__input">
    <span class = "custom__input-row">
        <input class="field" type="text">
        <span class="placeholder">Placeholder</span>
        <font-awesome-icon :icon="myIcon" class="icon" />
    </span>
</div>
*/
.custom__input{
  position: relative;
  width: 100%;

  .field {
    margin: 7px 0px;
  }
}

.custom__input-row{
  display:flex;
}

.custom__input-row input:focus + .placeholder,
.custom__input-row input:valid + .placeholder{
  top: 10px;
  left: 12px;
  font-size: 14px;
  color: $text;
}

.custom__input-row input:focus +.placeholder + .icon,
.custom__input-row input:valid +.placeholder + .icon{
  visibility: visible;
}

.field {
  width: 100%;
  font-size: 18px;
  padding: 1.3em .7em 0.5em 0.7em;
  margin: 10px auto;
  display: inline-block;
  background: rgba(126,126,126,0.1);
  border: 0px solid rgba(126,126,126,0.1);
  background-clip:padding-box;
  border-radius: 3px;
  box-sizing: border-box;
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  color: #525252;
  outline: none;
  // outline-color: rgba(126,126,126,0.1);
  outline-width: 1;

  &:hover{
    background: #e7e7e7;
  }

  // &:focus{
  //     // background: transparent;
  // }
}

.placeholder{
  pointer-events: none;
  position: absolute;
  top: 10px;
  left: 10px;
  font-weight: 400;
  font-size: 20px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  color: $secondary-text;
}

.placeholder__textarea{
  pointer-events: none;
  position: absolute;
  margin-top: -20px;
  padding: 0 .8em 0;
  font-weight: 400;
  font-size: 18px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  color: $secondary-text;
}

.custom__textarea{
  width: 100%;
  border:1px solid $light-grey;
  padding: .35em .5rem .35rem;
  line-height: 1.4;
  // outline: none;
  outline-color: $btn-primary;
}

.custom__input-row textarea:focus + .placeholder__textarea,
.custom__input-row textarea:valid + .placeholder__textarea{
  top: 24px;
  left: 2px;
  font-size: 14px;
  color: $secondary-text;
}

.custom__input-row textarea:focus,
.custom__input-row textarea:valid{
  border: 1px solid $secondary-text;
}

.password{
  padding-right: 45px;
}

.icon{
  top: 30px;
  right: 12px;
  position: absolute;
  font-size: 23px;
  cursor: pointer;
  visibility: hidden;

  .visible{
    visibility: visible;
  }
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
