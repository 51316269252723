// services.js
const state = {
    services: [
        { id: 'c1', service: 'Haar Knippen', price: '23' },
        { id: 'c2', service: 'Haar knippen / Degraderen', price: '25' },
        { id: 'c3', service: 'Baard Degraderen & Aflijnen', price: '20' },
        { id: 'c4', service: 'Baard Scheren & Aflijnen', price: '17' },
        { id: 'c5', service: 'Kinderen <8j', price: '20' },
        { id: 'c6', service: 'Tondeuse', price: '15' },
    ]
};

const getters = {
    allServices: (state) => state.services,
};

const actions = {
    fetchServices({ commit }) {
        // Simulating an API call, you can replace this with your actual data fetching logic
        const servicesData = state.services;
        commit('setServices', servicesData);
    },
};

const mutations = {
    setServices: (state, services) => {
        state.services = services;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
