<template>
  <ol class="day-of-week">
    <li v-for="weekday in weekdays" :key="weekday">{{ weekday }}</li>
  </ol>
</template>

<script>
const WEEKDAYS = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export default {
  name: "CalendarWeekdays",

  computed: {
    weekdays() {
      return WEEKDAYS;
    }
  }
};
</script>

<style scoped>
.day-of-week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  list-style-type: none;
  margin-bottom: 0.5rem;
}

.day-of-week > * {
  text-align: center;
  text-transform: uppercase;
  font-size: 11px;
  color: #8e97b3;
  font-weight: 500;
  letter-spacing: 2px;
  padding: 5px 0;
}

@media (max-width: 360px) {
  .day-of-week{
    margin: 0 0;
  }
}
</style>
