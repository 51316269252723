<template>
  <CalendarMonth/>
</template>

<script>
import CalendarMonth from "./calendar/CalendarMonth";

export default {
  components: {
    CalendarMonth,
  },
};
</script>

<style lang="scss" scoped>
/* Add your styles here */
</style>
