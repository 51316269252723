<template>
  <div class="calendar-table__col"
           :class="{
          'calendar-table__inactive': !day.isCurrentMonth || isMonday || isBeforeToday,
          'calendar-table__today': isToday,
          }">
        <div
            class="calendar-table__item"
            @click="handleClick">
          <span>{{ label }}</span>
        </div>
      </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "CalendarMonthDayItem",
  props: {
    day: {
      type: Object,
      required: true
    },

    isCurrentMonth: {
      type: Boolean,
      default: false
    },

    isToday: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    label() {
      return dayjs(this.day.date).format("D");
    },
    isMonday() {
      // Check if the current day is a Monday and it's in the current month
      return dayjs(this.day.date).day() === 1;
    },
    isBeforeToday() {
      return dayjs(this.day.date).isBefore(dayjs(), 'day');
    },
  },
  methods: {
    handleClick() {
      if(!this.isMonday && !this.isBeforeToday){
        this.$emit('click', this.day);
      }
    },
  },
};
</script>

<style scoped>
.calendar-table__item {
  border: 2px solid transparent;
  border-radius: 50%;
  color: #424588;
  font-size: 12px;
  font-weight: 700;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.calendar-table__item:hover {
  background: #f8fafa;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  transition: 0.2s all ease-in;
}
.calendar-table__header .calendar-table__col {
  display: inline-block;
  color: #99A4AE;
  font-size: 12px;
  font-weight: 700;
  padding: 12px 3px;
  text-align: center;
  text-transform: uppercase;
  width: 40px;
  height: 38px;
}
@media (min-width: 360px) {
  .calendar-table__header .calendar-table__col {
    width: 46px;
  }
}
@media (min-width: 410px) {
  .calendar-table__header .calendar-table__col {
    width: 54px;
  }
}
.calendar-table__body .calendar-table__col {
  width: 40px;
  height: 42px;
  padding-bottom: 2px;
}
@media (min-width: 360px) {
  .calendar-table__body .calendar-table__col {
    width: 46px;
    height: 48px;
  }
}
@media (min-width: 410px) {
  .calendar-table__body .calendar-table__col {
    width: 54px;
    height: 56px;
  }
}
.calendar-table__today .calendar-table__item {
  border-color: #FEFEFE;
  background-color: #F2F6F8;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}
.calendar-table__event .calendar-table__item {
  background-color: #caa569;
  border-color: #FEFEFE;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  color: #fff;
}
.calendar-table__event--long {
  overflow-x: hidden;
}
.calendar-table__event--long .calendar-table__item {
  border-radius: 0;
  border-width: 2px 0;
}
.calendar-table__event--start .calendar-table__item {
  border-left: 2px solid #fff;
  border-radius: 50% 0 0 50%;
}
.calendar-table__event--start.calendar-table__col:last-child .calendar-table__item {
  border-width: 2px;
}
.calendar-table__event--end .calendar-table__item {
  border-right: 2px solid #fff;
  border-radius: 0 50% 50% 0;
}
.calendar-table__event--end.calendar-table__col:first-child .calendar-table__item {
  border-width: 2px;
}
.calendar-table__inactive .calendar-table__item {
  color: #DCDCE3;
  cursor: default;
}
.calendar-table__inactive .calendar-table__item:hover {
  background: transparent;
  box-shadow: none;
}
.calendar-table__inactive.calendar-table__event .calendar-table__item {
  color: #fff;
  opacity: 0.25;
}
.calendar-table__inactive.calendar-table__event .calendar-table__item:hover {
  background: #caa569;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

/* Styles for tablets */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .calendar-table__item {
    font-size: 1.2rem;
  }
}
</style>
